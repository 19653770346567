<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import _ from 'lodash';
import { getScreenWH } from '@/utils/common';
import { mapState } from 'vuex';

export default {
  metaInfo() {
    return {
      title: this.globalData.webSeoTitle,
      meta: [
        { name: 'description', content: this.globalData.webSeoDescription },
        { name: 'keywords', content: this.globalData.webSeoKeywords },
      ],
    };
  },
  created() {
    this.init();
  },
  mounted() {
    window.addEventListener('resize', _.debounce(this.init));
  },
  computed: {
    ...mapState({
      globalData: (state) => state.sjx.globalData,
    }),
  },
  methods: {
    init() {
      const info = getScreenWH();
      // 统一1024以下响应为手机端
      this.$store.commit('IS_MOBILE', info?.width <= 1024);
      // 统一1180以下去除一些遮挡物（如滑动导航）
      this.$store.commit('IS_NEAR_MOBILE', info?.width <= 1180);
      this.$store.commit('VISIBLE_WH', info);
    },
  },
};
</script>
