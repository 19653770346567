export default {
  namespaced: true,
  state: {
    baseInfo: {},
  },
  mutations: {
    BASE_INFO(state, payload) {
      state.baseInfo = payload;
    },
  },
};
