import join from './join';
import about from './about';
import brand from './brand';

export default {
  namespaced: true,
  state: {
    globalData: {},
  },
  mutations: {
    GLOBAL_DATA(state, payload) {
      state.globalData = payload;
    },
  },
  modules: {
    join,
    about,
    brand,
  },
};
