import Vue from 'vue';
import Vuex from 'vuex';
import sjx from './ShuJiuXiang';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isMobile: false, // 是否为移动端（1024）
    isNearMobile: false, // 是否接近移动端（1180）
    visibleWH: { width: 0, height: 0 }, // 可视区域宽高
    hearderScrollMarks: {}, // 导航滚动标志
    navActives: {},
  },
  mutations: {
    IS_MOBILE(state, payload) {
      state.isMobile = payload;
    },
    IS_NEAR_MOBILE(state, payload) {
      state.isNearMobile = payload;
    },
    VISIBLE_WH(state, payload) {
      state.visibleWH = payload;
    },
    HEARDER_SCROLL_MARKS(state, payload) {
      state.hearderScrollMarks = { ...state.hearderScrollMarks, ...payload };
    },
    NAV_ACTIVES(state, payload) {
      state.navActives = { ...state.navActives, ...payload };
    },
  },
  actions: {},
  modules: {
    sjx,
  },
});
