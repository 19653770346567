import Vue from 'vue';
import {
  Divider,
  Backtop,
  Icon,
  Dialog,
  Image,
  Empty,
  Carousel,
  CarouselItem,
  Tabs,
  TabPane,
  Popover,
  Input,
  Button,
  Form,
  FormItem,
  Loading,
} from 'element-ui';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/styles/global.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '@/styles/ShuJiuXiang/quill.bubble.css';
import '@/styles/ShuJiuXiang/quill.core.css';
import '@/styles/ShuJiuXiang/quill.snow.css';
import Meta from 'vue-meta';

Vue.use(Meta);

AOS.init({
  duration: 800,
});

Vue.use(Divider);
Vue.use(Backtop);
Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Image);
Vue.use(Empty);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Popover);
Vue.use(Input);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Loading);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
