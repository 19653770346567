import mapIcon from '@/assets/ShuJiuXiang/map-icon.png';

// 图片居中
function calculateToCenter(imgEl, imgW, imgH, boxW, boxH) {
  if (!imgEl) return;
  const imgRatio = imgH / imgW;
  const boxRatio = boxH / boxW;
  if (imgRatio < boxRatio) {
    imgW = boxH / imgRatio;
    imgEl.style.height = boxH + 'px';
    imgEl.style.width = imgW + 'px';
    imgEl.style.top = 0;
    imgEl.style.left = -(imgW - boxW) / 2 + 'px';
  } else {
    imgH = boxW * imgRatio;
    imgEl.style.height = imgH + 'px';
    imgEl.style.width = boxW + 'px';
    imgEl.style.top = -(imgH - boxH) / 2 + 'px';
    imgEl.style.left = 0;
  }
}

// 获取容器宽高
function getContainerWH(el) {
  const { height = 0, width = 0 } = el?.getBoundingClientRect() || {};
  return { height, width };
}

// 获取窗口的文档显示区的宽高
function getScreenWH() {
  return {
    width: document.body.clientWidth,
    height: window.innerHeight,
  };
}

// 获取滚动条高度
function getScrollTop() {
  let st = 0;
  if (document?.documentElement?.scrollTop) {
    st = document.documentElement.scrollTop;
  } else if (document.body) {
    st = document.body.scrollTop;
  }
  return st;
}

// 判断dom上是否包含某个className
function getTargetByClass(target, className) {
  if (target === null) return null;
  const hasItem = target?.classList?.contains(className);
  if (hasItem) {
    return target;
  }
  return getTargetByClass(target?.parentNode, className);
}

// 显示地图
function ShowMap(coordinate, showmapInfo, zoom) {
  const arr = coordinate.split(',');
  const map = new window.BMap.Map('map');
  const point = new window.BMap.Point(...arr);
  const marker = new window.BMap.Marker(point, {
    icon: new window.BMap.Icon(mapIcon, new window.BMap.Size(64, 64)),
  });
  map.addOverlay(marker);
  marker.setAnimation(window.BMAP_ANIMATION_BOUNCE);
  map.centerAndZoom(point, zoom);
  map.disableScrollWheelZoom();
  const opts = {
    width: 400,
  };
  const infoWindow = new window.BMap.InfoWindow(showmapInfo, opts);
  marker.addEventListener('click', function() {
    const { width } = getScreenWH();
    if (width >= 641) {
      map.openInfoWindow(infoWindow, point);
    }
  });
}

// 获取锚点位置
function getElesTop(data = []) {
  let index = 0;
  const len = data.length - 1;
  const arr = [];
  while (index <= len) {
    const el = document.getElementById(data[index].key);
    if (!el) {
      continue;
    }
    arr[index] = el.offsetTop;
    index++;
  }
  return arr;
}

// 比较两个数组值是否全等
function compareArraysEqual(arr1 = [], arr2 = []) {
  return arr1.toString() === arr2.toString();
}

// 隔1000ms获取锚点位置，直到连续3次值相同，得到最后结果
function getPointsPos(data, time = 500) {
  return new Promise((resolve) => {
    let oldPos = getElesTop(data);
    let count = 1;
    const timer = setInterval(() => {
      count++;
      let newPos = getElesTop(data);
      if (compareArraysEqual(oldPos, newPos) && count === 3) {
        clearInterval(timer);
        resolve(newPos);
      } else {
        oldPos = newPos;
      }
    }, time);
  });
}

export {
  calculateToCenter,
  getContainerWH,
  getScreenWH,
  getScrollTop,
  getTargetByClass,
  ShowMap,
  getPointsPos,
};
