export default [
  {
    path: '/sjx',
    name: 'sjx',
    component: () => import('@/views/ShuJiuXiang'),
    children: [
      {
        path: 'home',
        name: 'sjxHome',
        component: () => import('@/views/ShuJiuXiang/Home'),
      },
      {
        path: 'news/:type', // 'activity' - 近期活动，'notice' - 新闻公告'，'video' - 视频中心
        name: 'sjxNews',
        component: () => import('@/views/ShuJiuXiang/News'),
      },
      {
        path: 'news/detail/:type/:id',
        name: 'sjxNewsDetail',
        component: () => import('@/views/ShuJiuXiang/News/Detail'),
      },
      {
        // 'special' - 特色菜品，'env' - 门店环境'，'shop' - 全国门店，'story' - 品牌故事
        path: 'brand',
        name: 'sjxBrand',
        component: () => import('@/views/ShuJiuXiang/Brand'),
        redirect: 'brand/special',
        children: [
          {
            path: 'special',
            name: 'sjxBrandSpecial',
            component: () => import('@/views/ShuJiuXiang/Brand/Special'),
          },
          {
            path: 'env',
            name: 'sjxBrandEnv',
            component: () => import('@/views/ShuJiuXiang/Brand/Env'),
          },

          {
            path: 'shop',
            name: 'sjxBrandShop',
            component: () => import('@/views/ShuJiuXiang/Brand/Shop'),
          },
          {
            path: 'story',
            name: 'sjxBrandStory',
            component: () => import('@/views/ShuJiuXiang/Brand/Story'),
          },
        ],
      },
      {
        path: 'about',
        name: 'sjxAbout',
        component: () => import('@/views/ShuJiuXiang/About'),
      },
      {
        path: 'join',
        name: 'sjxJoin',
        component: () => import('@/views/ShuJiuXiang/Join'),
      },
    ],
  },
  {
    path: '/sjx/brand/env/detail/:id',
    name: 'sjxBrandEnvDetail',
    component: () => import('@/views/ShuJiuXiang/Brand/Detail'),
  },
  {
    path: '/sjx/brand/special/detail/:id',
    name: 'sjxBrandSpecialDetail',
    component: () => import('@/views/ShuJiuXiang/Brand/SpecialDetail'),
  },
];
